import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import StockChart from "./shared/stockChart";
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  ExclamationIcon,
  CogIcon,
  EyeOffIcon,
  EyeIcon,
  InformationCircleIcon,
  TrashIcon,
  CheckCircleIcon,
  CollectionIcon,
  CursorClickIcon, MailOpenIcon,
  DocumentReportIcon,
  ChartSquareBarIcon,
  ClockIcon,
  TrendingUpIcon,
  GlobeIcon,
  MailIcon,
  DocumentDownloadIcon,
} from '@heroicons/react/outline';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'

import ReactTooltip from 'react-tooltip';

import { func } from 'prop-types'

//Importo Demoni per le tabelle
import { useTable } from "react-table";
import Table from "./Table";
import Loader from "./Loader";
import {CSVLink, CSVDownload} from 'react-csv';






const navigation = [
  { name: 'Riepilogo', href: '/dashboard', icon: HomeIcon, current: false },
  { name: 'Setta Macchina', href: '/set_lom', icon: CogIcon, current: false },
  { name: 'Storico Letture', href: '/history', icon: CollectionIcon, current: true },
//  { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
//  { name: 'Documents', href: '#', icon: InboxIcon, current: false },
//  { name: 'Reports', href: '#', icon: ChartBarIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function History() {
  //Setto gli state
  const [allUsers, viewUsers] = useState([{}])
  const [allLastMonth, viewLastMonth] = useState([{}])
  const [allLastMonth3, viewLastMonth3] = useState([{}])
  const [allLastMonth12, viewLastMonth12] = useState([{}])


  const [allEvents, viewEvents] = useState([{}])
  const [allLetture, viewLetture] = useState([{}])
  const [allLettureArea, viewLettureArea] = useState([{}])
  const [allLettureVelocita, viewLettureVelocita] = useState([{}])
  const [allLettureDistanza, viewLettureDistanza] = useState([{}])
  const [allLettureOre, viewLettureOre] = useState([{}])
  const [allLettureAreaTotale, viewLettureAreaTotale] = useState([{}])
  const [allLettureDistanzaTotale, viewLettureDistanzaTotale] = useState([{}])
  const [allLettureOreTotale, viewLettureOreTotale] = useState([{}])
  const [allLettureMaxVelocita, viewLettureMaxVelocita] = useState([{}])









  const [loading, setLoading] = useState(false)
  const [allCostumers, viewCostumers] = useState('')
  const [allUsersC, viewUsersC] = useState('')
  const [allApp, viewApp] = useState('')
  const cookies = parseCookies()
  const [isLoggedOut, setLogout] = useState(false)
  const [isExpanded, toggleExpansion] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  let history = useHistory()
  const [showsetAllProfile, setAllProfile] = useState([{}]);


  const indirzzi_email_loggato = cookies.email_merchant;
  const indirzzi_email_account = cookies.email;





  useEffect(() => {
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    }
  }, [setLogout])


  const logout = () => {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')

  }

  const CALLlogout = () => {
    setOpen(true);
  }





  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/users/fetchUtentiSistema/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllProfile(info.data)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Lista Letture della Giornata Area
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/readings/fetchSommaConteggiArea`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewLettureAreaTotale(info.data[0].Area)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Lista Letture della Giornata Area
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/readings/fetchSommaConteggiDistanza`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewLettureDistanzaTotale(info.data[0].Distanza)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Lista Letture della Giornata Area
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/readings/fetchSommaConteggiOre`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewLettureOreTotale(info.data[0].Ore)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Lista Letture della Giornata Area
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/readings/fetchMaxVelocita2`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewLettureMaxVelocita(info.data[0].MaxVelocita)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

   //Recupero Ultime Letture
   useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/readings/fetchAllDef`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers(info.data)
          setLoading(true)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Ultime Letture
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/readings/fetchLastMonth`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewLastMonth(info.data)
          setLoading(true)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Ultime Letture
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/readings/fetchLastMonth3`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewLastMonth3(info.data)
          setLoading(true)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Ultime Letture
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/readings/fetchLastMonth12`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewLastMonth12(info.data)
          setLoading(true)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  const stats = [
    { id: 1, name: 'Totale Distanza', stat: `${allLettureDistanzaTotale} m`, icon: DocumentReportIcon, change: '122', changeType: 'increase' },
    { id: 2, name: 'Totale Area', stat: `${allLettureAreaTotale} mq`, icon: GlobeIcon, change: '5.4%', changeType: 'increase' },
    { id: 3, name: 'Totale Ore', stat: `${allLettureOreTotale} h`, icon: ClockIcon, change: '3.2%', changeType: 'decrease' },
    { id: 4, name: 'Velocià Massima', stat: `${allLettureMaxVelocita} m/min`, icon: TrendingUpIcon, change: '3.2%', changeType: 'decrease' },

  ]


  const columns = useMemo(
    () => [
      {
        Header: "Data",
        accessor: "data",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{cell.row.values.data}</td>

        )
      },
      {
        Header: "Velocità (m/min)",
        accessor: "velocita",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cell.row.values.velocita}</td>

        )
      },
      {
        Header: "Distanza (m)",
        accessor: "distanza",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cell.row.values.distanza}</td>

        )
      },
      {
        Header: "Area (mq)",
        accessor: "area",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cell.row.values.area}</td>

        )
      },
      {
        Header: "Ore (h/cc)",
        accessor: "ore",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cell.row.values.ore}</td>

        )
      },
      {
        Header: "Data inserimento",
        accessor: "data_inserimento",
        Cell: cell => (
          <>
          <ReactTooltip/>
          <td className="flex px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cell.row.values.data_inserimento} <CheckCircleIcon  data-tip="Caricamento completato" className="h-5 w-5 ml-2 text-green-500" aria-hidden="true" /></td>
          </>
        )
      },
    ],
    []
  );

  const getData = () => [

    allUsers

  ];
  const data = useMemo(() => getData(), []);

  const csvData = allUsers;
  const csvData1 = allLastMonth;
  const csvData2 = allLastMonth3;
  const csvData3 = allLastMonth12;

  const dataOdiernaNew = new Date().toLocaleString().slice(0,-10);

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-12 w-auto"
                    src="https://fileserver.armandofratelli.it/upload/logo.png"
                    alt="Logo"
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                          'mr-4 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </nav>
              </div>
              <div className="flex-shrink-0 flex bg-gray-700 p-4">
                <a href="#" className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />

                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><spam>Ruolo: {capitalize(cookies.role) + "  "}</spam><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a onClick={() => CALLlogout()} >Logout</a></p>

                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="w-full"
                  src="https://fileserver.armandofratelli.it/upload/logo.png"
                  alt="Logo"
                />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
            <div className="flex-shrink-0 flex bg-gray-700 p-4">
              <a href="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />

                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><spam>Ruolo: {capitalize(cookies.role) + "  "}</spam><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a  onClick={() => CALLlogout()} >Logout</a></p>

                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6">

            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
              {/* Replace with your content */}



              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-3" >
              <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">


              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

              <h2 className="text-md leading-6 font-bold text-gray-800">Statistiche</h2>


              {/* Statistiche */}

              <div>

                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                      {stats.map((item) => (
                        <div
                          key={item.id}
                          className="relative bg-white pt-5 px-4 pb-1 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                        >
                          <dt>
                            <div className="absolute bg-red-500 rounded-md p-3">
                              <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
                          </dt>
                          <dd className="ml-16 pb-2 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>

                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>

              <div>

              <h2 className="text-md mt-3 leading-6 font-bold text-gray-800">Azioni</h2>


              <div className="flex mt-3 py-4">

              <CSVLink data={csvData} filename={`Export_${dataOdiernaNew}.csv`}>
              <button
                type="button"
                className="inline-flex items-center  px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <DocumentDownloadIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
               Esporta CSV Tutto

              </button>
             </CSVLink>

             <CSVLink data={csvData1} filename={`Export_${dataOdiernaNew}.csv`}>
              <button
                type="button"
                className="inline-flex items-center ml-3 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <DocumentDownloadIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
               Esporta CSV Ultimo mese

              </button>
             </CSVLink>

             <CSVLink data={csvData2} filename={`Export_${dataOdiernaNew}.csv`}>
              <button
                type="button"
                className="inline-flex items-center ml-3 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <DocumentDownloadIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
               Esporta CSV Ultimi 3 mesi

              </button>
             </CSVLink>

             <CSVLink data={csvData3} filename={`Export_${dataOdiernaNew}.csv`}>
              <button
                type="button"
                className="inline-flex items-center ml-3 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <DocumentDownloadIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
               Esporta CSV Ultimo anno

              </button>
             </CSVLink>



              </div>




              </div>

              <h2 className="text-md mt-3 leading-6 font-bold text-gray-800">Ultimi invi macchina</h2>

              <div className="mt-3 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">




              {/* Tabella Esercenti */}

              {loading ? <Table columns={columns} data={allUsers} pagSize={5} /> : <Loader /> }


                      </div>
                    </div>
                  </div>
                </div>

               </div>







               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
