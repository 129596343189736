import React, { useState } from 'react'
import {Link, Redirect, useHistory} from 'react-router-dom'
import axios from 'axios';
import { useAuth } from "../context/auth";
import { useForm } from 'react-hook-form'
import { setCookie } from 'nookies'

import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  CheckCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/outline'



  export default function ForgotPw(props) {

  // Managin login

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const history = useHistory()


  const { register, handleSubmit, formState: { errors } } = useForm();
        
  
  const onSubmit = data => {
  const email = data.email;
  const password = data.password;

        const forgot = async () => {
          try {
            
            const add = await axios.post(`${window.$apiEndpoint}/auth/forgot`, {
              email: email,
          })
            setSucc(true);
            setTimeout(() => {setSucc(false)},5000)
            setTimeout(() => {history.push('/login')},5300)
            
          } catch (err) {
            setErr(true)
            setTimeout(() => {setErr(false)},3500)
          }
        }
        forgot()


    }

  return (
    

      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-6 sm:px-6 lg:px-8"
      style={{
            backgroundColor: `#27719c`,
           
      }}>
      

      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">

      
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <div className="sm:mx-auto sm:w-full sm:max-w-md">
        
        <h3 className="my-3 text-center text-2xl font-extrabold text-grey-300">Password dimenticata?</h3>

      </div>
          <form className="space-y-5" onSubmit={handleSubmit(onSubmit)}>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Indrizzo email di registrazione 
              </label>
              <div className="mt-3">
                <input
                  id="email"
                  name="email"
                  type="email"
                  {...register("email", { required: true })}
                  autoComplete="email"
                  placeholder="Inserisci il tuo indirzzo email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {errors.email && <p>Il campo è obbligatorio</p>}

              </div>
            </div>

            <div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Recupera
              </button>

              <a href="/login"><p className="my-3 text-sm font-medium text-gray-400">Torna al login</p></a>

              {/* Stampo errore o messaggio di successo*/}

              { showErr ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> L'indirizzo email inserito non è corretto! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }
                  
                {showSucc ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center> Abbiamo inviato le nuove credenziali di accesso all'indirizzo associato al tuo account! </center></p>
                          <p className="text-sm font-medium text-green-800"><center> <strong> Controlla anche nella posta indesiderata.</strong></center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  )
}
