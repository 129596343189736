const Loader = () => {
    let circleCommonClasses = 'h-2 w-2 bg-current   rounded-full';

    return (
       

        <div className='flex py-2 px-2'>
            <div className={`${circleCommonClasses} mr-1 animate-bounce bg-indigo-400`}></div>
            <div
                className={`${circleCommonClasses} mr-1 Spinner bg-indigo-500	`}
            ></div>
            <div className={`${circleCommonClasses} Spinner2 bg-indigo-600	`}></div>
        </div>
    );
};

export default Loader;