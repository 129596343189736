import React, { useState } from 'react'
import {Link, Redirect} from 'react-router-dom'
import axios from 'axios';
import { useAuth } from "../context/auth";
import { useForm } from 'react-hook-form'
import { setCookie } from 'nookies'



  export default function Index(props) {

  // Managin login

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isLoggedInMerchant, setLoggedInMerchant] = useState(false);
  const [isLoggedInEvents, setLoggedInEvents] = useState(false);


  const [isError, setIsError] = useState(false);
  const { setAuthTokens } = useAuth();


        const { register, handleSubmit, formState: { errors } } = useForm();
        const onSubmit = data => {
        const email = data.email;
        const password = data.password;

        axios.post(`${window.$apiEndpoint}/auth/login`, {
          email: email,
          password: password
        }).then(result => {
          if (result.data.auth === true) {
            setAuthTokens(result.data);
            setCookie(null, 'token', result.data.token , {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            })
            setCookie(null, 'fname', result.data.fname , {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            })
            setCookie(null, 'lname', result.data.lname , {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            })
            setCookie(null, 'email', result.data.email , {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            })
            setCookie(null, 'email_merchant', email , {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            })
            setCookie(null, 'role', result.data.role , {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            })
            const expiration = new Date(new Date().getTime() + 1000 * 60 * 60 * 12);
            setCookie(null, 'expiration', expiration, {
              maxAge: 30 * 24 * 60 * 60,
            })
            

            //Eseguo le eccezioni sui ruoli per l'accesso

            if (result.data.role === 'admin') {

              //Utente admin rimando a dashboard 
              setLoggedIn(true); 

            } else if (result.data.role === 'merchant') {

              //Utente esercente
              setLoggedInMerchant(true);

            } else if (result.data.role === 'events') {

              //Utente esercente
              setLoggedInEvents(true);
            }

          } else {
            setIsError(true);
          }
        }).catch(e => {
          setIsError(true);
        });
    }

          //Riamando alla dashboard corretta a seconda del ruolo utente 
          if (isLoggedIn) {
            return <Redirect to="/dashboard" />;
          }

          if (isLoggedInMerchant) {
            return <Redirect to="/dashboard_merchant" />;
          }

          if (isLoggedInEvents) {
            return <Redirect to="/dashboard_events" />;
          }


  return (
    <div className="min-h-screen bg-white flex">

      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <center><img
              style={{width: '62%', marginBottom:'1vh',}}
              src="https://fileserver.armandofratelli.it/upload/logo.png"
              alt="Logo Armando Fratelli"
            />
              <p className="block text-sm font-medium text-gray-500">PORTALE CLOUD RACCOLTA DATI 4.0</p>
            
            </center>
            <p className="mt-2 text-sm text-gray-600">



            </p>
          </div>

          <div className="mt-8">
            <div>
              <div>

                <div className="mt-1 grid grid-cols-3 gap-3">





                </div>
              </div>


            </div>

            <div className="mt-1">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Indrizzo Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      {...register("email", { required: true })}
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors.email && <p>Il campo è obbligatorio</p>}

                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      {...register("password", { required: true })}
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors.password && <p>Il campo è obbligatorio</p>}

                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                      Ricordami
                    </label>
                  </div>

                  <div className="text-sm">
                    <a href="/forgotpw" className="font-medium text-red-600 hover:text-red-500">
                      Hai dimenticato la password?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    Accedi
                  </button>
                </div>
              </form>
              < br />
              {isError && (
                <p style={{fontSize: '1.3vh'}}>Il nome utente o la password forniti non sono corretti!</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
      <div>
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://fileserver.armandofratelli.it/upload/sfondo_1.jpg"
          alt=""
        />
        </div>
      </div>
    </div>
  )
}
