import { Line } from "react-chartjs-2";
import React from "react";


const buildData = ({chartData}) => ({
    labels: chartData.labels,
    datasets: [
        {
            label: "Area (mq)",
            backgroundColor: "rgba(58, 71, 153,0.1)",
            borderColor: "rgba(58, 71, 153,0.6)",
            pointBackgroundColor: "#4287f5",
            data: chartData.data,
            yAxisID: 'y-axis-1',
            fill: true,
        },
        {
            label: 'Velocita (m/min)',
            data: chartData.velocita,
            fill: false,
            backgroundColor: 'rgb(54, 162, 235, 0.1)',
            borderColor: 'rgba(54, 162, 235, 0.6)',
            yAxisID: 'y-axis-1',
            fill: true,
          },
          {
            label: 'Distanza (m)',
            data: chartData.distanza,
            fill: false,
            backgroundColor: 'rgba(199, 153, 80, 0.1)',
            borderColor: 'rgba(199, 153, 80, 0.6)',
            yAxisID: 'y-axis-1',
            fill: true,
          },
          {
            label: 'Ore (h.cc)',
            data: chartData.ore,
            fill: false,
            backgroundColor: 'rgba(23, 163, 42, 0.1)',
            borderColor: 'rgba(23, 163, 42, 0.6)',
            yAxisID: 'y-axis-1',
            fill: true,
          },
    ],
});

const options = {
    legend: {
        display: true,
        label: "Test",
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    fontColor: "#4287f5",
                },
                gridLines: {
                    display: false,
                },
                id: 'y-axis-1',
            },
        ],
        xAxes: [
            {
                ticks: {
                    fontColor: "#4287f5",
                },
                gridLines: {
                    color: "#4287f5",
                    borderDash: [5, 5],
                    zeroLineColor: "#4287f5",
                    zeroLineBorderDash: [5, 5],
                },
            },
        ],
    },
    layout: {
        padding: {
            right: 20,
        },
    },
};

const numberToFix = (number, fix) => (number || 0).toFixed(fix)


const StockChart = ({ info }) => {
    const data = buildData(info);

    return (
        <>
            <div
                className="sm:rounded-lg border-b border-gray-200 shadow overflow-hidden w-full mt-2 md:flex"
                style={{ maxWidth: "1000vh" }}
            >
                <div className="flex w-full md:w-3/4 px-5 pb-4 pt-8  text-white items-center">
                    <Line data={data} options={options} />
                </div>
                <div className="flex w-full md:w-1/4 p-10 bg-gray-100 text-gray-600 items-center">
                    <div className="w-full">
                        <h3 className="text-lg font-semibold leading-tight text-gray-00">
                          <p className="font-bold">MACCHINA:</p>{info.stockFullName}
                        </h3>
                        <h6 className="text-sm leading-tight mb-5">
                        Grafico aggiornato alle:  <strong>{info.dataOdierna}</strong>
                        </h6>

                        <h6 className="text-sm mt-2 leading-tight mb-2">
                        Area Totale:  <strong>{info.totaleArea}</strong>
                        </h6>
                        <h6 className="text-sm leading-tight mb-2">
                        Velocita Massima:  <strong>{info.maxVelocita}</strong>
                        </h6>
                        <h6 className="text-sm leading-tight mb-2">
                        Distanza Totale:  <strong>{info.totaleDistanza}</strong>
                        </h6>
                        <h6 className="text-sm leading-tight mb-2">
                        Ore Totali:  <strong>{info.totaleOre}</strong>
                        </h6>
                        <div className="flex w-full items-end mb-6">
                         
                        
                        </div>
                        
                        
                    </div>
                </div>
                </div>
        </>
    );
};

export default StockChart;