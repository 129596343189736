import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import {decode as base64_decode, encode as base64_encode} from 'base-64';

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  ExclamationIcon,
  SearchCircleIcon,
  SpeakerphoneIcon,
  MapIcon,
  ViewGridAddIcon,
  CogIcon,
  CameraIcon,
  UploadIcon,
  CheckIcon,
  CheckCircleIcon,
  TrashIcon,
  HeartIcon,
  checkCircleIcon,
  CollectionIcon
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';
import { ChevronLeftIcon, FilterIcon, MailIcon, InformationCircleIcon, PhoneIcon, SearchIcon, LocationMarkerIcon, PencilAltIcon, PlusIconSolid, PencilIcon, ViewGridIcon as ViewGridIconSolid,
  ViewListIcon } from '@heroicons/react/solid'

//Importo componente per le Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//Importo demone per il crop delle immagini
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'


const styles = (theme) => ({
  cropContainer: {
    width: '100%',
    height: 400,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'flex-end',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
})

const navigation = [
  { name: 'Riepilogo', href: '/dashboard', icon: HomeIcon, current: false },
  { name: 'Setta Macchina', href: '/set_lom', icon: CogIcon, current: false },
  { name: 'Storico Letture', href: '/history', icon: CollectionIcon, current: false },
//  { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
//  { name: 'Documents', href: '#', icon: InboxIcon, current: false },
//  { name: 'Reports', href: '#', icon: ChartBarIcon, current: false },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function Profilo() {
  //Setto gli state
  const [imageCover, setImageCover] = useState([{}])
  const [imageProfilo, setImageProfilo] = useState([{}])
  const [imageNegozio, setimageNegozio] = useState([{}])
  const [Allinfo, setAllInfo] = useState([{}]);
  const [AllTags, setTags] = useState([{}]);
  const [AllOrari, setOrari] = useState([{}]);
  const [AllProdotti, setProdotti] = useState([{}]);
  const [AllCoordinate, setCoordinate] = useState([{}]);
  const [loading, setLoading] = useState(false)
  const [allCostumers, viewCostumers] = useState('')
  const [allUsersC, viewUsersC] = useState('')
  const [allApp, viewApp] = useState('')
  const [immagineCliccata, setImmagineCliccata] = useState('')
  const cookies = parseCookies()
  const [isLoggedOut, setLogout] = useState(false)
  const [isExpanded, toggleExpansion] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [openModalDet, setopenModalDet] = useState(false)
  const [openModalUploadProfile, setOpenModalUploadProfile] = useState(false)
  const [openModalUploadNegozio, setOpenModalUploadNegozio] = useState(false)
  const [openModalUpdateInfo, setOpenModalUpdateInfo] = useState(false)
  const [openModalUpdatePassword, setOpenModalUpdatePassword] = useState(false)
  const [openModalUpdateOrari, setOpenModalUpdateOrari] = useState(false)
  const [openModalCropProfile, setOpenModalCropProfile] = useState(false)
  const [imageProfile, setimageProfile] = useState('')
  const [imageProfileUrl, setimageProfileUrl] = useState(null)
  const [styleHover, setStyleHover] = useState({display: 'none'});
  const [styleHoverImg, setStyleHoverImg] = useState({display: 'block'});
  const [predefinita, setPredefinita] = useState(true)











  const cancelButtonRef = useRef(null)
  let history = useHistory()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { register: register2 , handleSubmit: handleSubmit2 , formState: { errors: errors2 } } = useForm();
  const { register: register3 , handleSubmit: handleSubmit3 , formState: { errors: errors3 } } = useForm();

  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadStatus2, setUploadStatus2] = useState('');
  const [uploadStatus3, setUploadStatus3] = useState('');

  const indirzzi_email_loggato = cookies.email_merchant;
  const indirzzi_email_account = cookies.email;
  const [tabIndex, setTabIndex] = useState(0);
  const [currentTabProfilo, setcurrentTabProfilo] = useState(true);
  const [currentTabPOfferte, setcurrentTabOfferte] = useState(false);
  const [currentTabOrari, setcurrentTabOrari] = useState(false);
  const [activeTab, setActiveTab] = useState('Profilo')
  const [isAdmin, setisAdmin] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [showSucc1, setSucc1] = useState(false);
  const [showErr1, setErr1] = useState(false);
  const [showSucc2, setSucc2] = useState(false);
  const [showErr2, setErr2] = useState(false);
  const [showSucc10, setSucc10] = useState(false);
  const [showErr10, setErr10] = useState(false);
  const [showPreSucc, setPreSucc] = useState('');
  const [showPreErr, setPreErr] = useState('');
  const [showErr12, setErr12] = useState(false);


  
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

   
 
  
  useEffect(() => {
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    }
  }, [setLogout])

 
  const logout = () => {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    
  }

  const CALLlogout = () => {
    setOpen(true);
  }

  
  //Richiama dati 
  const Richfetch = async () => {
    const info = await axios.get(`${window.$apiEndpoint}/users/fetchUtentiSistema/${indirzzi_email_loggato}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
        setAllInfo(info.data)
        
        
      
    } catch (e) {
      console.log(e)
    }
  }
  Richfetch();
  


  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/users/fetchUtentiSistema/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllInfo(info.data)
          
          
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/users/fetchUtentiSistema/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){

          setTimeout(() => {
            setAllInfo(info.data)
            setLoading(true)
            }, 1000);
          
          
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [Allinfo])

   //Controllo se la pagina è controllata da un Amministrstore oppure solamnete dall'Esercente 
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      try {
        if(mounted){   

        if (indirzzi_email_loggato === indirzzi_email_account) {
          
           //Sono Esercente
           setisAdmin(false)
         
          //Controllo Se sono uno degli amministratori
          if (indirzzi_email_loggato === 'info@finestrapp.it' || indirzzi_email_loggato === 'info@tribusadv.com') {
          //Sono Amministrazione ma nel mio account 
          setisAdmin(true) 
          } else {
           //Sono Esercente
           setisAdmin(false)
          }

      
        } else {

          //Sono Amministratore 
          setisAdmin(true)
        
        } 
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  


  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Funzione che trasforma la stringa in mauscolo
  function capitalizeUpper(s)
  {
    //Trasformo tutto in Maiscolo
    const n = s.toUpperCase();
    //Stampo
    return n;
  }

  //Funzione che controlla se la variabile dell'orario è Null
  function checkNull(string)
  {
    if (string === "") {
      
      return "Chiuso";

    } else {

      return string;

    
    }
  }

  //Chiamata post per inserimento Tags
  const onSubmitPassword = data => {
    
    const email = indirzzi_email_loggato;
    const password = data.password;
    const password1 = data.password1;


    //Controlle le password inserite sono uguali

    if (password === password1) {
    //Uguale Eseguo
    
    
    const addM = async () => {
      try {
        
        const add = await axios.post(`${window.$apiEndpoint}/auth/cambiaPassword`, {
          password: password,
          email: email,
      }, {
          headers: {
              'x-access-token' : cookies.token
          }
      })
        setSucc1(true);
        setTimeout(() => {setSucc1(false)},3000)
        setTimeout(() => {setOpenModalUpdatePassword(false)},3000)
       
      } catch (err) {
        setErr1(true)
        setTimeout(() => {setErr1(false)},3000)
      }
    }
    addM()

  } else {
 
    setErr12(true)
        setTimeout(() => {setErr12(false)},3000)

  } 
  }


  const CallUpdatePassword = () => {
    setOpenModalUpdatePassword(true);
  }
  
  const CallUpload = () => {
    setOpenModalUpload(true);
  }


  //Caricamento Immagine 
  const imageHandler = (event) => {

    const file = event.target.files[0];
    const formData = new FormData()
    formData.append('image', file)
    formData.append("email", indirzzi_email_loggato);


    fetch(`${window.$apiEndpoint}/merchant/uploadAvatar`, {
        method: 'POST',
        body: formData,
        headers: {
              'x-access-token' : cookies.token,
              'Accept': 'multipart/form-data'
          },
      })
      .then(res => res.json())
      .then(res => {
        setUploadStatus(res.msg);


        
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-12 w-auto"
                    src="https://fileserver.armandofratelli.it/upload/logo.png"
                    alt="Logo"
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                          'mr-4 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </nav>
              </div>
              <div className="flex-shrink-0 flex bg-gray-700 p-4">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={window.$fileserverEndpoint + Allinfo[0].url_imagine_profilo_dash}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><spam>Ruolo: {capitalize(cookies.role) + "  "}</spam><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a onClick={() => CALLlogout()} >Logout</a></p>

                    </div>
                  </div>
                
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="h-13 w-auto"
                  src="https://fileserver.armandofratelli.it/upload/logo.png"
                  alt="Logo"
                />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
            <div className="flex-shrink-0 flex bg-gray-700 p-4">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src={window.$fileserverEndpoint + Allinfo[0].url_imagine_profilo_dash}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><span>Ruolo: {capitalize(cookies.role) + "  "}</span><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a  onClick={() => CALLlogout()} >Logout</a></p>

                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">


          <div className="py-6 mt-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 bg-white rounded-xl	">
              {/* Replace with your content
              
               #Contenuto Merchant Dashboard
              
               */}
              



               <article>

              {/* Profile header */}
            
              <div className="max-w-8xl mx-auto px-2 sm:px-6 lg:px-8">
                  <div className="mt-2  py-8 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">

                  <div className="bg-white w-full ">
                  <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Impostazioni</h2>
                  <h2 className="mt-2 xl:mb-12 lg:mb-12 md:mb-12 sm:mb-12 mb-16 text-sm text-gray-500 text-left">In questa sezione puoi cambiare la tua password di accesso e il tuo avatar.</h2>



                            
                  
                          <section aria-labelledby="profile-overview-title">
                <div className="rounded-lg bg-white overflow-hidden shadow">
                  <h2 className="sr-only" id="profile-overview-title">
                    Profile Overview
                  </h2>
                  <div className="bg-white w-full p-6">
                    <div className="sm:flex sm:items-center sm:justify-between">
                      <div className="sm:flex sm:space-x-5">
                        <div className="flex-shrink-0">
                          <img className="mx-auto h-20 w-20 rounded-full" src={window.$fileserverEndpoint + Allinfo[0].url_imagine_profilo_dash} alt="" />
                        </div>
                        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                          <p className="text-sm font-medium text-gray-600">Bentornato,</p>
                          <p className="text-xl font-bold text-gray-900 sm:text-2xl">{Allinfo[0].fname} {Allinfo[0].lname} </p>
                          <p className="text-sm font-medium text-gray-500"><span className="text-sm font-medium text-gray-600" >Ruolo: </span> {Allinfo[0].role}</p>
                        </div>
                      </div>
                      <div className="mt-5 flex justify-center sm:mt-0">
                        <a
                          type="button"
                          onClick={() => CallUpdatePassword()}
                          className="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                          Cambia Password
                        </a>
                      </div>

                      <div className="mt-5 flex justify-center sm:mt-0">
                        <a
                           type="button"
                          onClick={() => CallUpload()}
                          
                          className="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                          Cambia Immagine Profilo
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
                      <div  className="px-6 py-5 text-sm font-medium text-center">
                        <span className="text-gray-900"></span>{' '}
                        <span className="text-gray-600"></span>
                      </div>
                    
                  </div>
                </div>
              </section>



                        
                    
                        
                        
                          </div>
                    </div>
                    </div>
                    

              
              </article>




               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

   


   {/* Modale di modifica Password */}

   <Transition.Root show={openModalUpdatePassword} as={Fragment}>
      <Dialog
        as="div"
        static
        className="w-full h-full fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openModalUpdatePassword}
        onClose={setOpenModalUpdatePassword}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12 sm:p-6">
              <div>
                
                <div className="mt-3 sm:mt-5">
                 
                  <div className="mt-2">
                    


                    {/* Corpo del modale */}

                    <div className="space-y-6">
      

      <div className="bg-white  px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Modifica la tua Password</h3>
            <p className="mt-1 text-sm text-gray-500">Completa i seguenti form per cambiare la tua password.</p>

            { showErr1 ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> Errore nel aggiornamento dei dati! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }


                  { showErr12 ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> Le password inserite non coincidono! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }
                  
                {showSucc1 ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Password aggiornata con successo!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={handleSubmit2(onSubmitPassword)}>
            <div className="flex flex-col">
                    
                              </div>
                            

                <div className="grid grid-cols-6 gap-6 mt-5">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Nuova Password
                  </label>
                  <input
                    type="text"
                    name="password"
                    id="password"
                    placeholder="password"
                    {...register2("password", { required: true })}

                    autoComplete="given-name"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors2.password && <p className="mt-1 text-sm text-gray-500">Il campo è obbligatorio</p>}

                  <label htmlFor="password1" className="block mt-2 text-sm font-medium text-gray-700">
                    Conferma Nuova Password
                  </label>
                  <input
                    type="text"
                    name="password1"
                    id="password1"
                    placeholder="conferma password"
                    {...register2("password1", { required: true })}

                    autoComplete="given-name"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors2.password1 && <p className="mt-1 text-sm text-gray-500">Il campo è obbligatorio</p>}

                </div>
                </div>
              <div className="mt-5 sm:mt-6 flex flex-row-reverse ">
                <button
                  type="submit"
                  className="w-max mx-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                >
                  Modifica Password
                </button>
                <button
                  type="button"
                  className="mt-3 w-max inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpenModalUpdatePassword(false)}
                  ref={cancelButtonRef}
                >
                  Chiudi
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
                    
                  </div>
                </div>
              </div>
              
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    


    


    {/** Modale Upload immagine di copertina*/}

    <Transition.Root show={openModalUpload} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={openModalUpload} onClose={setOpenModalUpload}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <UploadIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                  </Dialog.Title>
                  <div className="mt-2">
                    

                  <div className="space-y-1 text-center">
                    <div className=" text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span>Carica immagine</span>
                        <input id="file-upload" name="image" accept="image/*" multiple={false} type="file" onChange={imageHandler} className="sr-only" />
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG fino a 2MB</p>
                    <p className="text-sm text-gray-600" >{uploadStatus}</p>
                  </div>

                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={() => setOpenModalUpload(false)}
                >
                  Torna al tuo profilo
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

    


   

              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
