// src/Table.js
import { Fragment, useState, useRef, useEffect, useMemo } from 'react'
import { useTable, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'  // new
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid';

import {
    CalendarIcon,
    ChartBarIcon,
    FolderIcon,
    HomeIcon,
    InboxIcon,
    MenuIcon,
    UsersIcon,
    ScaleIcon,
    ShieldCheckIcon,
    UserGroupIcon,
    XIcon,
    Logout,
    GiftIcon,
    UserGroup,
    ExclamationIcon,
    CogIcon,
    EyeOffIcon,
    EyeIcon,
    InformationCircleIcon,
    TrashIcon,
  
  } from '@heroicons/react/outline'
import { Button, PageButton } from './shared/Button'
import axios from 'axios'
import ReactTooltip from 'react-tooltip';


const { parseCookies, destroyCookie, setCookie } = require('nookies');



function Table({ columns, data, pagSize }) {
  

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    //new
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    

    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
    initialState: { pageSize: pagSize }

  },
    useGlobalFilter,
    usePagination,  // new
  )

  

 
  // Render the UI for your table
  return (
    <>
          <div className="flex gap-x-2">

    
          </div>

    <div className="mt-2 py-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b mx-4 border-gray-200 sm:rounded-lg">
              <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
        {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
                <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          {...column.getHeaderProps()}
                        >{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page.map((row, i) => {  // new
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="px-1 py-1 whitespace-nowrap"
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
    </table>
    </div>
          </div>
        </div>
      </div>
     {/* new */}
 
    </>

  );
}



export default Table;