import React from 'react'
import {Switch, Route} from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Dashboard from './pages/dashboard'
import Login from './pages/login'
import ForgotPw from './pages/forgotpw'
import Profilo from './pages/profilo'
import History from './pages/history'
import SetLom from './pages/set_lom'








const Routes = (props) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        roles={['admin']}
        path="/dashboard"
        component={Dashboard}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['admin', 'merchant']}
        path="/profilo"
        component={Profilo}
      ></PrivateRoute>
       <PrivateRoute
        exact
        roles={['admin', 'merchant']}
        path="/history"
        component={History}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['admin', 'merchant']}
        path="/set_lom"
        component={SetLom}
      ></PrivateRoute>
      <PrivateRoute exact path="/" component={Dashboard}></PrivateRoute>
      <Route exact path="/login" component={Login}></Route>
      <Route exact path="/forgotpw" component={ForgotPw}></Route>
    </Switch>
  );
}
export default Routes
